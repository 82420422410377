import React from 'react';
import '../styles/Realizacje.css'
import { galleryHorizontal } from '../components/GalleryData';

import { footerAudyt } from '../layouts/Links';
import { NavLink } from 'react-router-dom';

const Realizacje = () => {
    const imagesH = galleryHorizontal.map(item => (
        <div> <div className="opisGallery">{item.opis}</div> <img src={item.img} alt={item.imga} />   </div>
    ))
   

    const menu = footerAudyt.map(item => (
        <div key={item.name}>
            <NavLink to={item.path} exact={item.exact ? item.exact : false} >{item.name}</NavLink>
        </div>
    ))
    return (
        <>
        <div className="title">
            <div className="titleGallery">
                <h1 class="h1-opt-2">Nasze realizacje</h1>
            </div>
        </div>
            
        <div className="galleryContent">
           
                <div className="galleryMain">
            <div className="imagesGallery">
       
                {imagesH}
              
            </div>
                       </div>
            </div>
            <div className="margin">
            <div className="audytButton">
                         {menu}
                        </div>
                        </div>
        </>
    );
}
export default Realizacje;

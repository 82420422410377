import React from 'react';
import  {InfoData}  from '../components/InfoData'
import '../styles/Info.css';

import { footerAudyt } from '../layouts/Links';
import { NavLink } from 'react-router-dom';

const Info = () => {

    const menu = footerAudyt.map(item => (
        <div key={item.name}>
            <NavLink to={item.path} exact={item.exact ? item.exact : false} >{item.name}</NavLink>
        </div>
    ))
    
    return (
        <>
        <div className="infoTitle">
        <h1 class="h1-opt">Pytania i odpowiedzi</h1>
        </div>
        <div className="mainPageInfo">
            <div className="mainInfo">
                {InfoData.map(item => (
        <div className="textInfo">
            <div className="textInfoH">
            <p class="h2ToP-info">{item.title}</p>
            </div>
            <div className="textInfoP">
            <p>{item.content}</p>
            <div className="marginInfo"></div>
            </div>
        </div>
    ))}
            </div>
            </div>
            <div className="margin">
            <div className="audytButton">
                         {menu}
                        </div>
                        </div>
        </>
    )
}

export default Info;

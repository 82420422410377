import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from './HomePage';
import Contact from '../pages/Contact';
import House from '../pages/House';
import Company from '../pages/Company';
import Rolnik from '../pages/Rolnik';
import Info from '../pages/Info';
import Realizacje from '../pages/Realizacje';
import Calc from '../pages/Calc';

const Page = () => {
    return (
        <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/house" component={House}/>
            <Route path="/company" component={Company}/>
            <Route path="/rolnik" component={Rolnik} />
            <Route path="/info" component={Info}/>
            <Route path="/done" component={Realizacje} />
            <Route path="/calc" component={Calc} />
            <Route path="/contact" component={Contact} />
        </Switch>
    )
}

export default Page;
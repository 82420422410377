import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import '../styles/Rolnik.css';
import rolnik from '../images/homePage/rolnik2.jpg';
import panel from '../images/mono1.png';
import falownik from '../images/falownik.png';
import { footerAudyt } from '../layouts/Links';
import { NavLink } from 'react-router-dom';

import logo1 from '../images/logo/logo1.png';
import logo2 from '../images/logo/logo2.png';
import logo3 from '../images/logo/logo3.png';
import logo4 from '../images/logo/logo4.png';
import logo5 from '../images/logo/logo41.png';
import logo6 from '../images/logo/logo42.png';

import logo11 from '../images/logo2/logo1.png';
import logo12 from '../images/logo2/logo2.png';
import logo13 from '../images/logo2/logo3.png';
import logo14 from '../images/logo2/logo4.png';

import EuroIcon from '@material-ui/icons/Euro';
import DescriptionIcon from '@material-ui/icons/Description';
import FlashOnIcon from '@material-ui/icons/FlashOn';



const Rolnik = () => {
    const menu = footerAudyt.map(item => (
        <div key={item.name}>
            <NavLink to={item.path} exact={item.exact ? item.exact : false} >{item.name}</NavLink>
        </div>
    ))
    return (
        <>
        <Router>
        <div className="mainPicture">
            <img src={rolnik} alt="fotowoltaika"/>
            </div>
        <div className="Page">
              <div className="titleWrapper">
                <h1>Fotowoltaika dla rolnika</h1>
              </div>
              <div className="SeoText">
                <p>Ofertę firmy ECO GREEN kierujemy między innymi do osób, które prowadzą swoją rolniczą działalność. Inwestycja w panele słoneczne może okazać się w takim przypadku bardzo doskonałym pomysłem. Dlaczego? Przede wszystkim ze względu na ekologiczny charakter instalacji fotowoltaicznej. Posiadanie własnej “zielonej elektrowni” może na przykład służyć do zasilania specjalistycznych urządzeń rolniczych. Ogniwa fotowoltaiczne pozwolą albo na całkowite obniżenie rachunków za prąd, albo na ich zmniejszenie.</p>
                <p>Rolnicy, którzy zdecydują się na wybór fotowoltaiki, mogą liczyć między innymi na takie formy finansowania, jak: odliczenie od podatku rolnego bądź odliczenie od podatku VAT. Dzięki temu pośrednio zmniejsza się cena zakupu instalacji. Z kolei inwestycja ta pozwala na uzyskanie niezależności energetycznej - możesz zapomnieć o podwyżkach cen dystrybucji prądu! Zapraszamy do wyboru ECO GREEN – dostarczamy wszystkie potrzebne urządzenia i dokonujemy ich montażu.</p>
              </div>
                    <div className="ikony">
                     <div className="kafelki">
                         <div className="ikona">
                         <EuroIcon
                                style={{ fontSize: '120px' , color: 'green'}}>
                                </EuroIcon>
                         </div>
                         <div className="ikonaTitle">
                             <p>Odliczenie od podatku rolnego</p>
                         </div>
                         <div className="ikonaOpis">
                             <p>Możliwość odliczenia 25% inwestycji od podatku rolnego w okresie do 15 lat.</p>
                         </div>
                     </div>

                     <div className="kafelki">
                         <div className="ikona">
                         <DescriptionIcon
                                style={{ fontSize: '120px' , color: 'green'}}>
                                </DescriptionIcon>
                         </div>
                         <div className="ikonaTitle"><p>Odliczenie podatku VAT</p></div>
                         <div className="ikonaOpis"><p>Możliwość odliczenia 23% podatku VAT, dla rolników będących jego płatnikami.</p></div>
                     </div>

                     <div className="kafelki">
                         <div className="ikona">
                         <FlashOnIcon
                                style={{ fontSize: '120px' , color: 'green'}}>
                                </FlashOnIcon>
                         </div>
                         <div className="ikonaTitle"><p>Niezależność energetyczna</p></div>
                         <div className="ikonaOpis"><p>Uzyskaj niezależność energetyczną, a  podwyżki cen prądu  nie będą Ciebie dotyczyły. </p></div>
                     </div>
                </div>
<div className="panele">
    <div className="panelLewy">
    <img src={panel} alt=""/>
    </div>
    <div className="opisLogo">
        <div className="opis">
            <p>Oferujemy panele słoneczne tylko uznanych producentów</p>
        </div>
        <div className="wszystkieLogo">
       <div className="logoLine">
       <img src={logo2} alt="Hyundai Energy Solutions"/>
        <img src={logo6} alt="JinkoSolar"/>
       </div>
       <div className="logoLine">
       <img src={logo1} alt="Longi"/>
        <img src={logo4} alt="Trina Solar"/>
       </div>
       <div className="logoLine">
       <img src={logo5} alt="JA Solar"/>
        <img src={logo3} alt="LG"/>
       </div>
        
        
        </div>
    </div>
</div>

<div className="panele">
    <div className="panelLewy">
    <img src={falownik} alt=""/>
    </div>
    <div className="opisLogo">
        <div className="opis">
            <p>Montujemy tylko te falowniki, które doceniane są na całym świecie</p>
        </div>
        <div className="wszystkieLogo">
       <div className="logoLine">
       <img src={logo12} alt="Huawei"/>
        <img src={logo11} alt="Fronius"/>
       </div>
       <div className="logoLine">
       <img src={logo13} alt="SolarEdge"/>
        <img src={logo14} alt="Kehua Tech"/>
       </div>
        
        
        </div>
    </div>
</div>

            </div>
            <div className="margin">
            <div className="audytButton">
                         {menu}
                        </div>
                        </div>
        </Router>
        </>
    )
}

export default Rolnik;

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "../styles/HomePage.css";
import firma from "../images/homePage/firma2.jpg";

import { footerAudyt } from "../layouts/Links";
import { NavLink } from "react-router-dom";

import panel from "../images/mono1.png";
import falownik from "../images/falownik.png";

import logo1 from "../images/logo/logo1.png";
import logo2 from "../images/logo/logo2.png";
import logo3 from "../images/logo/logo3.png";
import logo4 from "../images/logo/logo4.png";
import logo5 from "../images/logo/logo41.png";
import logo6 from "../images/logo/logo42.png";

import logo11 from "../images/logo2/logo1.png";
import logo12 from "../images/logo2/logo2.png";
import logo13 from "../images/logo2/logo3.png";
import logo14 from "../images/logo2/logo4.png";
import DescriptionIcon from "@material-ui/icons/Description";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

const Company = () => {
  const menu = footerAudyt.map((item) => (
    <div key={item.name}>
      <NavLink to={item.path} exact={item.exact ? item.exact : false}>
        {item.name}
      </NavLink>
    </div>
  ));
  return (
    <>
      <Router>
        <div className="mainPicture">
          <img src={firma} alt="fotowoltaika" />
        </div>
        <div className="Page">
          <div className="titleWrapper">
          <h1>Fotowoltaika dla firmy</h1>
          </div>
          <div className="SeoText">
            <p>Prowadzenie własnego biznesu, chociaż pozwala na generowanie względnie dużych zysków, to jednak wiąże się również z koniecznością ponoszenia pewnych kosztów. Na szczęście są sposoby na to, aby je nieco obniżyć. Jednym z takich sposób jest założenie w siedzibie swojej firmy instalacji fotowoltaicznej, która w zależności od ilości zakupionych paneli słonecznych, może pomóc w obniżeniu rachunków częściowo lub w całości - nawet do 0 zł!</p>
            <p>Przedsiębiorcy, którzy zdecydują się na inwestycję w nasze ekologiczne rozwiązanie mogą skorzystać z atrakcyjnej formy finansowania – odliczenia podatku VAT od kosztów, jakie poniosło się na zakup naszych usług i dostarczonych urządzeń. To jednak nie wszystko, bowiem instalacja fotowoltaiczna z całą pewnością szybko się zwróci Twojej firmie. Dlatego też warto już teraz zainwestować w panele słoneczne i cieszyć się w firmie darmową, ekologiczną energią elektryczną.</p>
          </div>
          <div className="ikony">
            <div className="kafelki">
              <div className="ikona">
                <DescriptionIcon
                  style={{ fontSize: "120px", color: "green" }}
                ></DescriptionIcon>
              </div>
              <div className="ikonaTitle">
                <p>Odliczenie podatku VAT</p>
              </div>
              <div className="ikonaOpis">
                <p>
                  Odliczysz całość 23% podatku VAT, oraz będziesz amortyzował
                  koszty instalacji.
                </p>
              </div>
            </div>
            <div className="kafelki">
              <div className="ikona">
                <TrendingUpIcon
                  style={{ fontSize: "120px", color: "green" }}
                ></TrendingUpIcon>
              </div>
              <div className="ikonaTitle">
                <p>Wzrost rentowności</p>
              </div>
              <div className="ikonaOpis">
                <p>
                  Zmniejsz koszty prowadzenia swojej działalności dzięki własnej
                  produkcji prądu.
                </p>
              </div>
            </div>

            <div className="kafelki">
              <div className="ikona">
                <FlashOnIcon
                  style={{ fontSize: "120px", color: "green" }}
                ></FlashOnIcon>
              </div>
              <div className="ikonaTitle">
                <p>Niezależność energetyczna</p>
              </div>
              <div className="ikonaOpis">
                <p>
                  Uzyskaj niezależność energetyczną, a podwyżki cen prądu nie
                  będą Ciebie dotyczyły.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="panele">
            <div className="panelLewy">
              <img src={panel} alt="" />
            </div>
            <div className="opisLogo">
              <div className="opis">
                <p>Oferujemy panele słoneczne tylko uznanych producentów</p>
              </div>
              <div className="wszystkieLogo">
                <div className="logoLine">
                  <img src={logo2} alt="Hyundai Energy Solutions" />
                  <img src={logo6} alt="JinkoSolar" />
                </div>
                <div className="logoLine">
                  <img src={logo1} alt="Longi" />
                  <img src={logo4} alt="Trina Solar" />
                </div>
                <div className="logoLine">
                  <img src={logo5} alt="JA Solar" />
                  <img src={logo3} alt="LG" />
                </div>
              </div>
            </div>
          </div>

          <div className="panele">
            <div className="panelLewy">
              <img src={falownik} alt="" />
            </div>
            <div className="opisLogo">
              <div className="opis">
                <p>
                  Montujemy tylko te falowniki, które doceniane są na całym
                  świecie
                </p>
              </div>
              <div className="wszystkieLogo">
                <div className="logoLine">
                  <img src={logo12} alt="Huawei" />
                  <img src={logo11} alt="Fronius" />
                </div>
                <div className="logoLine">
                  <img src={logo13} alt="SolarEdge" />
                  <img src={logo14} alt="Kehua Tech" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin">
          <div className="audytButton">{menu}</div>
        </div>
      </Router>
    </>
  );
};

export default Company;

import React from 'react';
import { NavLink } from 'react-router-dom';

import { menuList } from './Links';

import '../styles/Navigation.css';

const Navigation = () => {

    const menu = menuList.map(item => (
        <div key={item.name}>
            <NavLink to={item.path} exact={item.exact ? item.exact : false} >{item.name}</NavLink>
        </div>
    ))

    return (
        <div className="mainboxNavigation">
            <div className="menuNavigation">
                {menu}
            </div>
        </div>

    );
}
 
export default Navigation;
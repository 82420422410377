export const galleryHorizontal = [
    {
        img: '/images/realizacje/8.png',
        imga: 'Instalacja fotowoltaiczna 3,2 kWp',
        opis: '3,2 kWp'
    },
    {
        img: '/images/realizacje/40.jpg',
        imga: 'Instalacja fotowoltaiczna 14,8 kWp',
        opis: '14,8 kWp'
    },
   
    {
        img: '/images/realizacje/9.png',
        imga: 'Instalacja fotowoltaiczna 3,6 kWp',
        opis: '3,6 kWp'
    },
    {
        img: '/images/realizacje/19.png',
        imga: 'Instalacja fotowoltaiczna 7 kWp',
        opis: '7 kWp'
    },
    {
        img: '/images/realizacje/20.png',
        imga: 'Instalacja fotowoltaiczna 7,4 kWp',
        opis: '7,4 kWp'
    },
    {
        img: '/images/realizacje/36.jpg',
        imga: 'Instalacja fotowoltaiczna 13,3 kWp',
        opis: '13,3 kWp'
    },
    {
        img: '/images/realizacje/100.jpg',
        imga: 'zabezpieczenia fotowoltaiczne',
    },
]

export const galleryVertical = [
    {
        img: '/images/gallery/vertical/14.png',
        imga: 'instalacja fotowoltaiczna',
    },
    
    
    {
        img: '/images/gallery/vertical/9.png',
        imga: 'instalacja fotowoltaiczna',
    },

{
    img: '/images/gallery/vertical/16.png',
    imga: 'instalacja fotowoltaiczna',
},
{
    img: '/images/gallery/vertical/17.png',
    imga: 'instalacja fotowoltaiczna',
},
{
    img: '/images/gallery/vertical/18b.png',
    imga: 'instalacja fotowoltaiczna',
},


{
    img: '/images/gallery/vertical/15.png',
    imga: 'instalacja fotowoltaiczna',
},
]
export const footerFoto = [
    {
        img: '/images/gallery/paneleFooter.jpg',
        imga: 'fotowoltaika',
    },
]

import React from 'react'
import img from '../images/HeaderTransparent.png';
import '../styles/Header.css';

const Header = () => {
    return ( 
        <div className="Header">
            <div>
            <a href="/"><img src={img} alt="Eco Green"/></a>
            </div>
        </div>
     );
}
 
export default Header;

import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import '../styles/HomePage.css'
import main from '../images/homePage/main.jpg'
import home from '../images/homePage/home2.jpg'
import firma from '../images/homePage/firma.jpg'
import rolnik from '../images/homePage/rolnik.jpg'

import { footerAudyt } from './Links'

import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SettingsIcon from '@material-ui/icons/Settings'
import EuroIcon from '@material-ui/icons/Euro'
import SecurityIcon from '@material-ui/icons/Security'
import DoneIcon from '@material-ui/icons/Done'

const Page = () => {
  const menu = footerAudyt.map((item) => (
    <div key={item.name}>
      <a href={item.path}>
        {item.name}
      </a>
    </div>
  ))
  return (
    <div>
      <Router>
        <div className="Section1">
          <img src={main} alt="obraz" />
        </div>
        <div className="SeoText">
          <h1>Eco Green - fotowoltaika, panele słoneczne</h1>
          <p>Marzenia o darmowej energii elektrycznej wcale nie muszą należeć jedynie do wytworów fantazji. Wręcz przeciwnie, w dobie nowoczesnej technologii jest to jak najbardziej realne. Wszystko dzięki instalacjom fotowoltaicznym. Panele słoneczne, dzięki wykorzystaniu określonych zjawisk fizycznych, produkują nie tylko darmowy, ale przede wszystkim ekologiczny prąd. Podczas jego generowania bowiem nie powstają zanieczyszczenia, a ponadto paliwo do jego wytworzenia jest praktycznie nieskończone.</p>
          <h2>Fotowoltaika dla domu, dla firm lub dla rolników</h2>
          <p>Dostarczamy gotowe rozwiązania dla wszystkich! Jesteśmy w stanie założyć ogniwa fotowoltaiczne (a także całą potrzebną instalację) w domach prywatnych naszych Klientów. To jednak nie wszystko, bowiem naszą ofertę kierujemy również do przedsiębiorców, którzy poszukują nowych możliwości na obniżenie kosztów prowadzenia działalności. Rolnicy z kolei mogą z powodzeniem zarabiać, decydując się na założenie fotowoltaicznej farmy na wolnych obszarach posiadanej ziemi.</p>
          <h2>Mogilno w Kujawsko-pomorskim – odkryj wysokiej klasy panele słoneczne</h2>
          <p>Dostarczamy panele słoneczne wszystkim tym, którzy nie chcą płacić więcej za prąd, zmniejszyć rachunki, żyć ekologiczne, a jednocześnie cieszyć się pełną wygodą. Oferujemy nie tylko montaż urządzeń, ale też fachowe doradztwo, pomoc w uzyskaniu dofinansowania, a także dajemy gwarancję niezawodności na 25 lat!</p>
        </div>
        <div className="mainIcons">
          <div className="kafelek">
            <div className="ikona">
              <DoneIcon style={{ fontSize: '100px', color: 'green' }}></DoneIcon>
            </div>
            <div className="podpisIkony">Fachowe doradztwo</div>
          </div>
          <div className="kafelek">
            <TrendingUpIcon style={{ fontSize: '100px', color: 'green' }}>fontSize'large',</TrendingUpIcon>
            <p>Najwyższa jakość</p>
          </div>
          <div className="kafelek">
            <SecurityIcon style={{ fontSize: '100px', color: 'green' }}></SecurityIcon>
            <p>Gwarancja niezawodności 25 lat</p>
          </div>
        </div>
        <div className="mainIcons">
          <div className="kafelek">
            <EuroIcon style={{ fontSize: '100px', color: 'green' }}></EuroIcon>
            <p>Pomagamy w uzysaniu dotacji</p>
          </div>
          <div className="kafelek">
            <SettingsIcon style={{ fontSize: '100px', color: 'green' }}></SettingsIcon>
            <p>Własne ekipy monterskie</p>
          </div>
          <div className="kafelek">
            <ThumbUpIcon style={{ fontSize: '100px', color: 'green' }}></ThumbUpIcon>
            <p>Konkurencyjne ceny</p>
          </div>
        </div>
        <div className="Section2">
          <div className="kafelek2">
            <a href="/house/">
              <div className="podpis2">
                <div className="podpisHover">Dla domu</div>
              </div>
              <img src={home} alt="Dla domu" />
            </a>
          </div>
          <div className="kafelek2">
            <a href="/company/">
              <div className="podpis2">
                <div className="podpisHover">Dla firmy</div>
              </div>
              <img src={firma} alt="Dla firmy" />
            </a>
          </div>
          <div className="kafelek2">
            <a href="/rolnik/">
              <div className="podpis2">
                <div className="podpisHover">Dla rolnika</div>
              </div>
              <img src={rolnik} alt="Dla rolnika" />
            </a>
          </div>
        </div>

        <div className="audytButton">{menu}</div>
      </Router>
    </div>
  )
}

export default Page

import React from "react";

import "../styles/Footer.css";

import RoomIcon from "@material-ui/icons/Room";
import CallIcon from "@material-ui/icons/Call";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";

const Footer = () => {
  return (
    <div className="mainBoxFooter">
      <div className="footerContent">
        <div className="ikonaContainer">
          <div className="ikonaLeft">
            <div className="ikonaFooter">
              <div className="ikona">
                <RoomIcon style={{ fontSize: "60px", color: "white" }}>
                  fontSize'large',
                </RoomIcon>
              </div>
              <div className="ikonaOpis">
                <p>Eco Green</p>
                <p>ul. Konopnickiej 20b</p>
                <p>88-300 Mogilno</p>
                <p>woj. kujawsko-pomorskie</p>
                <p>NIP: 5571675171</p>
              </div>
            </div>
            <div className="ikonaFooter">
              <div className="ikona">
                <CallIcon style={{ fontSize: "55px", color: "white" }}>
                  fontSize'large',
                </CallIcon>
              </div>
              <div className="ikonaOpis">
                <p>+48 726 98 63 63</p>
                <p>+48 697 63 48 93</p>
              </div>
            </div>
            <div className="ikonaFooter">
              <div className="ikona">
                <MailOutlineIcon style={{ fontSize: "55px", color: "white" }}>
                  fontSize'large',
                </MailOutlineIcon>
              </div>
              <div className="ikonaOpis">
                <p>biuro@ecogreen-oze.pl</p>
              </div>
            </div>
          </div>
          <div className="mainOpis">
            <div className="footerOpis">
              <h4>ECO GREEN - fotowoltaika</h4>{" "}
              <p>
                {" "}
                To najlepsze rozwiązanie dla domu, firmy oraz gospodarstwa
                rolnego. Tworzymy instalacje fotowoltaiczne oparte o podzespoły
                doceniane na całym świecie. Wyróżnia nas profesjonalizm oraz
                sumienne podejście do każdej instalacji.
              </p>
            </div>
            <div className="footerOpisIkony">
              <div className="ikona">
                <FacebookIcon style={{ fontSize: "55px", color: "white" }}>
                  fontSize'large',
                </FacebookIcon>
              </div>
              <div className="ikona">
                <InstagramIcon style={{ fontSize: "55px", color: "white" }}>
                  fontSize'large',
                </InstagramIcon>
              </div>
              <div className="ikona">
                <YouTubeIcon style={{ fontSize: "55px", color: "white" }}>
                  fontSize'large',
                </YouTubeIcon>
              </div>
              <div className="ikona">
                <LinkedInIcon style={{ fontSize: "55px", color: "white" }}>
                  fontSize'large',
                </LinkedInIcon>
              </div>
              <div className="ikona">
                <TwitterIcon style={{ fontSize: "55px", color: "white" }}>
                  fontSize'large',
                </TwitterIcon>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="titleFooter">
          <div className='titleFooter1'>
          <span>Umów się na bezpłatną konsultację.</span>
          </div>
          <div className='titleFooter2'>
          <p>Nasi eksperci dobiorą dla Ciebie idealną wielkość instalacji dopasowaną do wszystkich Twoich potrzeb. </p>
          </div>
  </div> */}
      </div>
      <div className="footerLegal">
        <span className="footerCopyrights">{"ECO GREEN © 2021"}</span>
      </div>
    </div>
  );
};

export default Footer;

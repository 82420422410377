import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "../styles/Rolnik.css";
import home from "../images/homePage/home3.jpg";

import { footerAudyt } from "../layouts/Links";
import { NavLink } from "react-router-dom";

import panel from "../images/mono1.png";
import falownik from "../images/falownik.png";

import logo1 from "../images/logo/logo1.png";
import logo2 from "../images/logo/logo2.png";
import logo3 from "../images/logo/logo3.png";
import logo4 from "../images/logo/logo4.png";
import logo5 from "../images/logo/logo41.png";
import logo6 from "../images/logo/logo42.png";

import logo11 from "../images/logo2/logo1.png";
import logo12 from "../images/logo2/logo2.png";
import logo13 from "../images/logo2/logo3.png";
import logo14 from "../images/logo2/logo4.png";

import EuroIcon from "@material-ui/icons/Euro";
import HouseIcon from "@material-ui/icons/House";
import FlashOnIcon from "@material-ui/icons/FlashOn";

const House = () => {
  const menu = footerAudyt.map((item) => (
    <div key={item.name}>
      <NavLink to={item.path} exact={item.exact ? item.exact : false}>
        {item.name}
      </NavLink>
    </div>
  ));
  return (
    <>
      <Router>
        <div className="mainPicture">
          <img src={home} alt="fotowoltaika" />
        </div>
        <div className="Page">
          <div className="titleWrapper">
          <h1>Fotowoltaika dla domu</h1>
          </div>
          <div className="SeoText">
            <p>Nie zastanawiaj się dłużej nad wyborem fotowoltaiki dla domu. Nasze instalacje pozwolą Ci na generowanie darmowego prądu prosto ze słońca, co przekłada się w dużym stopniu na ogromne oszczędności. Dostarczane przez nas urządzenia sprawią, że nie tylko możesz obniżyć rachunki za prąd, ale nawet całkowicie o nich zapomnieć. Wszystko zależy od liczby paneli słonecznych, jakie zdecydujesz się zakupić, a także zapotrzebowania energetycznego w Twoim gospodarstwie domowym.</p>
            <p>Nie tylko zajmujemy się montażem, ale także następnie ewentualnym serwisem ogniw fotowoltaicznych. Co więcej, pomagamy uzyskać dofinansowanie, między innymi z bezzwrotnej dotacji lub ulgi termomodernizacyjnej. Proponujemy falowniki między innymi takich marek, jak Solaredge, Huawei czy Fornius. Panele słoneczne z kolei to produkty firmy Longi, LG lub Hyundai. Odkryj wygodę, spraw, żeby Twój dom stał się miejscem ekonomicznym i ekologicznym z ogniwami fotowoltaicznymi od ECO GREEN.</p>    
          </div>
          <div className="ikony">
            <div className="kafelki">
              <div className="ikona">
                <EuroIcon
                  style={{ fontSize: "120px", color: "green" }}
                ></EuroIcon>
              </div>
              <div className="ikonaTitle">
                <p>Dotacja bezzwrotna 5000zł</p>
              </div>
              <div className="ikonaOpis">
                <p>
                  Pomożemy Ci w uzyskaniu dotacji z programu "Mój Prąd", aby
                  zwrot Twojej inwestycji był jeszcze szybszy, a Ty żebyś
                  cieszył się z zaoszczędzonych pieniędzy.
                </p>
              </div>
            </div>

            <div className="kafelki">
              <div className="ikona">
                <HouseIcon
                  style={{ fontSize: "120px", color: "green" }}
                ></HouseIcon>
              </div>
              <div className="ikonaTitle">
                <p>Ulga Termomodernizacyjna</p>
              </div>
              <div className="ikonaOpis">
                <p>
                  Skorzystaj z Ulgi Termomodernizacyjnej na Twój dom w PIT,
                  dzięki temu odliczysz 17%, 19% lub 32% od poniesiontch kosztów
                  instalacji fotowoltaicznej.
                </p>
              </div>
            </div>

            <div className="kafelki">
              <div className="ikona">
                <FlashOnIcon
                  style={{ fontSize: "120px", color: "green" }}
                ></FlashOnIcon>
              </div>
              <div className="ikonaTitle">
                <p>Niezależność energetyczna</p>
              </div>
              <div className="ikonaOpis">
                <p>
                  Uzyskaj niezależność energetyczną, a podwyżki cen prądu nie
                  będą Ciebie dotyczyły.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="panele">
            <div className="panelLewy">
              <img src={panel} alt="" />
            </div>
            <div className="opisLogo">
              <div className="opis">
                <p>Oferujemy panele słoneczne tylko uznanych producentów</p>
              </div>
              <div className="wszystkieLogo">
                <div className="logoLine">
                  <img src={logo2} alt="Hyundai Energy Solutions" />
                  <img src={logo6} alt="JinkoSolar" />
                </div>
                <div className="logoLine">
                  <img src={logo1} alt="Longi" />
                  <img src={logo4} alt="Trina Solar" />
                </div>
                <div className="logoLine">
                  <img src={logo5} alt="JA Solar" />
                  <img src={logo3} alt="LG" />
                </div>
              </div>
            </div>
          </div>

          <div className="panele">
            <div className="panelLewy">
              <img src={falownik} alt="" />
            </div>
            <div className="opisLogo">
              <div className="opis">
                <p>
                  Montujemy tylko te falowniki, które doceniane są na całym
                  świecie
                </p>
              </div>
              <div className="wszystkieLogo">
                <div className="logoLine">
                  <img src={logo12} alt="Huawei" />
                  <img src={logo11} alt="Fronius" />
                </div>
                <div className="logoLine">
                  <img src={logo13} alt="SolarEdge" />
                  <img src={logo14} alt="Kehua Tech" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="margin">
          <div className="audytButton">{menu}</div>
        </div>
      </Router>
    </>
  );
};

export default House;

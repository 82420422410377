export const menuList = [
    { name: "Start", path: "/", exact: true },
    { name: "Dla domu", path: "/house/"},
    { name: "Dla firmy", path: "/company/" },
    { name: "Dla rolnika", path: "/rolnik/" },
    { name: "O fotowoltaice", path: "/info/" },
    { name: "Realizacje", path: "/done/" },
    { name: "Kalkulator", path: "/calc/" },
    { name: "Kontakt", path: "/contact/" },
  ]
  export const menuList2 = [
    { name: "Zamów bezpłatny audyt", path: "/contact/" },
  ]
  export const footerAudyt = [
    { name: "Chcę bezpłatną konsultację z ekspertem", path: "/contact/" }
  ]
  export const footerList = [
    { name: "Start", path: "/", exact: true },
    { name: "Serwis i Konserwacja", path: "/services/" },
    { name: "Kontakt", path: "/contact/" },
  ]
  

export const InfoData = [
    {
        id: 0,
        title: 'Po jakim czasie instalacja się zwróci? ',
        content:'W zależności wielkości instalacji oraz wysokości autokonsumpcji, czyli bieżącego zużycia wyprodukowanej energii. Okres zwrotu dla PROSUMENTA przy uwzględnionej dotacji z rządowego programu Mój Prąd wynosi od 4 lat. ', 
    },
    {
        id: 1,
        title: 'Ulga termomodernizacyjna. Co to jest i kto może z niej skorzystać?',
        content:'Ulga termomodernizacyjna polega na odliczeniu od podatku dochodowego poniesionych wydatków związanych z montażem instalacji fotowoltaicznej. Z ulgi mogą skorzystać właściciele domów. Odliczamy 18% lub 19%, lub 32% w zależności na jakim progu podatkowym jesteśmy.', 
    },
    {
        id: 2,
        title: 'Jakie są formy dofinansowań?',
        content:'Najbardziej popularnym programem dofinansowania do fotowoltaiki jest "Mój Prąd", gdzie jest zwracane 5000zł w formie dotacji. Kolejnym programem jest "Czyste Powietrze", ale przy instalacji fotowoltaicznej musi być również zmodernizowane źródło ciepła. Są jeszcze lokalne programy dofinansować, ale tutaj o szczegóły musisz zapytać w swoim Urzędzie Gminy.', 
    },
    {
        id: 3,
        title: 'Czy z programu "Mój Prąd" mogą skorzystać firmy?',
        content:'Nie. Z programu "Mój Prąd" mogą skorzystać osoby fizyczne.', 
    },
    {
        id: 4,
        title: 'Kto składa wniosek do NFOŚiGW programu "Mój Prąd"',
        content:'Klient. Wniosek oraz załączniki można pobrać ze strony https://mojprad.gov.pl/', 
    },
    {
        id: 5,
        title: 'Czy muszę płacić za wymianę licznika?',
        content:'Prosument nie płaci za wymianę licznika jeśli wymiana jest na licznik dwukierunkowy. ', 
    },
    {
        id: 6,
        title: 'Ile czasu czeka się na wymianę licznika na dwukierunkowy?',
        content:'Na wymianę licznika na dwukierunkowy operator ma 30 dni. Średni czas oczekiwania to około 3 tygodnie od dnia wpłynięcia wniosku do zakładu energetycznego', 
    },
    {
        id: 7,
        title: 'Ile energii jest w stanie wyprodukować instalacja w ciągu roku?',
        content:'Ilość wyprodukowanej energii zależy od usytuowania instalacji względem południa. W Polsce przyjmuje się średni uzysk energii z 1 kWp zamontowanych paneli w ilości 1100 kWh, gdy panele skierowane są w południową stronę.', 
    },
    {
        id: 8,
        title: 'Czy potrzebuję pozwolenia budowlanego, aby zainstalować fotowoltaikę na moim dachu?',
        content:'Mikro instalacje do 50 kWp są wykonywane na zgłoszenie bez pozwoleń. Powyżej tej mocy budowa instalacji wymaga pozwoleń, co my, jako firma, możemy wykonać za naszych klientów kompleksowo. ', 
    },
    {
        id: 9,
        title: 'Chcę instalację fotowoltaiczną, ale mój dach nie jest skierowany na południe – jakie mam możliwości?',
        content:'Instalację można wykonać w kierunkach wschód-zachód jak również południowy wschód i południowy zachód. Uzyski energii z instalacji o takich kierunkach są bardzo zadowalające.', 
    },
    {
        id: 10,
        title: 'Czy jest możliwość rozłożenia modułów na więcej niż jednej połaci dachowej ?',
        content:'Tak. W takim przypadku stosujemy falowniki z dwoma wejściami MPPT, lub jeśli sytuacja jest bardziej skomplikowana to stosowane są optymalizatory do każdego z modułów.', 
    },
    {
        id: 11,
        title: 'Czy można użyć większego falownika, żeby Klient mógł w przyszłości rozbudować instalację?',
        content:'Tak można, jednak zbyt duże przewymiarowanie falownika względem instalacji może skutkować spadkiem wydajności działania instalacji. ', 
    },
    {
        id: 12,
        title: 'Jaki jest uzysk mocy z paneli latem, a jaki zimą?',
        content:'Produkcja prądu jest przez cały rok, jednak zimą jest znacznie mniejsza. To około 15% produkcji rocznej.', 
    },
    {
        id: 13,
        title: 'Co się stanie z nadwyżkami jeśli ich nie wykorzystam?',
        content:'Jeżeli po upływie roku nie wykorzystamy nadwyżek zmagazynowanej energii w ZE, przechodzą one na poczet Dystrybutora energii, a to oznacza, że nasza instalacja jest za duża, dlatego nasi doradczy dobiorą dla Ciebie idealną wielkość instalacji, abyś nie przepłacał.', 
    },
    {
        id: 14,
        title: 'Czy zimą muszę dopłacać do energii?',
        content:'Nie, chyba że instalacja wyprodukowała za mało prądu co wskazywałoby na źle dobraną wielkość instalacji. Energię zmagazynowaną latem wykorzystujemy zimą. Z energetyki rozliczamy się za cały rok. .', 
    },
    {
        id: 15,
        title: 'Pytania i odpowiedzi - bezpiecznik klienta',
        content:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veritatis, dolores aliquid! Voluptates soluta quas vel corrupti earum alias quae perspiciatis! Nostrum cum consectetur id. Veritatis, placeat. Illo ducimus deserunt repellat.', 
    },

]
import React, {useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Slider} from '@material-ui/core';
import '../styles/Calc.css';
import logo from '../images/logo.jpg';

import { footerAudyt } from '../layouts/Links';
import { NavLink } from 'react-router-dom';


const Calc = () => {
const [x, setx] = useState(200) 
const getValue = (e, value) => {  
setx(value)
    }  
    const y = x*0.022;
    const year = x*0.022*1070;
    const price = x*0.022*3460;
    const saveYear = x*12
    const finalPrice = Math.round((price - 5000)*0.81)

    const menu = footerAudyt.map(item => (
        <div key={item.name}>
            <NavLink to={item.path} exact={item.exact ? item.exact : false} >{item.name}</NavLink>
        </div>
    ))
    return (
        <>
        <Router>
            <div className="mainPage">
            <div className="sliderTitle">
                <div className="logo">
                <img src={logo} alt="logo"/>
                </div>
                <div className="title">
                    <h1>Fotowoltaika kalkulator - wylicz koszt instalacji</h1>
                </div>
                    </div>
                    <div className="sliderMain">
                        <div className="sliderDescription">
                            <h3>Ile miesięcznie płacisz za prąd?</h3>
                        </div>
                <div className="slider">
                <Slider
                defaultValue={200}
                min={100}
                max={1500}
                step={10}
                onChange={getValue}
                />
                <div> 
                <p>Wysokość miesięcznego rachunku: <b>{x} zł</b></p>
                </div>
                </div>
                </div>
                <div className="sliderDataMain">
                    <div className="sliderDataDescription">
                    <h3>Preferowana wielkość instalacji:</h3>
                    <div className="sliderData">
                        <p>Wielkość instalacji: {Math.round(y*100)/100} kWp</p>
                        <p>W ciągu roku Twoja instalacja wyprodukuje: {Math.round(year)} kWh</p>
                        <p>Koszt brutto instalacji od: {Math.round(price)} zł</p>
                    </div>
                    </div>
                    <div className="sliderDataDescription">
                        <h3>Twoje oszczędności:</h3>
                        <div className="sliderData">
                        <p>W ciągu roku zaoszczędzisz: {saveYear} zł</p>
                        <p>Inwestycja zwróci się po: {Math.ceil(finalPrice/saveYear)} latach.</p>
                        <p>W ciągu 25 lat zaoszczędzisz: {Math.round(((saveYear*25)*1.25)-price)} zł</p>
                        </div>
                        </div>
                </div>
                <div className="endTitle">
                <p>Koszt brutto po dofinansowaniu i uldze w podatku: <b>{finalPrice} zł</b> </p>
                </div>
            </div>
            <div className="margin"></div>
            <div className="SeoText">
                <p>Ilość generowanego prądu przez instalację fotowoltaiczną zależy przede wszystkim od jej wielkości - liczby paneli słonecznych, jakie zamontujemy. Możliwe jest założenie wystarczającej liczby paneli, tak aby zakupiona instalacja w pełni zaspokajała zapotrzebowanie gospodarstwa domowego lub przedsiębiorstwa na energię elektryczną. Jaka powinna być jednak optymalna wielkość instalacji w Twoim domu lub firmie? W końcu za duża nie dość, że pochłonie więcej pieniędzy to dodatkowo nie ma sensu produkować więcej prądu niż potrzebujemy.</p>
                <p>Dlatego też zachęcamy do skorzystania z kalkulatora instalacji fotowoltaicznej. Wystarczy tylko, że na suwaku zaznaczysz, ile miesięcznie kosztują Cię opłaty za prąd. Na tej podstawie wyliczane jest, jakiej wielkości instalacja zaspokoi potrzeby Twojego gospodarstwa domowego na energię elektryczną. Wyliczana jest także cena usługi montażu i zakupu paneli słonecznych, a także okres, w jakim inwestycja powinna Ci się zwrócić.</p>
            </div>
            <div className="margin">
            <div className="audytButton">
                         {menu}
                        </div>
                        </div>
        </Router>
        </>
    )
}
export default Calc;

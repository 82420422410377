import React from 'react';
import './styles/App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import Header from './layouts/Header';
import Navigation from './layouts/Navigation';
import Page from './layouts/Page';
import Footer from './layouts/Footer';

function App() {
  return (
    <Router>
      <header>
          <Header />
        </header>
      <div className="menu">
        <Navigation />
      </div>
      <div className="app">
        <main>
          <section className="page">
            <Page />
          </section>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
    

  );
}

export default App;

import React from 'react';
import '../styles/Contact.css';
import { Prompt } from 'react-router-dom';
import mapa from '../images/mapa.png';

import logo from '../images/logo2.jpg';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class Contact extends React.Component {
    state = {
        message: '',
        email: '',
        phone: '',
        isEmpty: true,
    }
    handleChange = (e) => {
        if (e.target.value.length > 0) {
            this.setState({
                message: e.target.value,
                isEmpty: false
            })
        } else
            this.setState({
                message: e.target.value,
                isEmpty: true,
            })
    }
    handleEmail = (e) => {
        this.setState({
            email: e.target.value,
        })
    }
    handlePhone = (e) => {
        this.setState({
            phone: e.target.value
        })
    }

    handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...this.state })
        })
        e.preventDefault()
        this.setState({
            message: '',
            email: '',
            phone: '',
            isEmpty: true,
        })
    }
    render() {
        return (
          <>
            <div className="contact">
              <form onSubmit={this.handleSubmit}>
                <h1 className="title">Umów się na bezpłatne spotkanie z doświadczonym doradcą</h1>
                <textarea
                  placeholder="Wpisz wiadomość..."
                  name="message"
                  id="message"
                  rows="4"
                  value={this.state.message}
                  onChange={this.handleChange}
                ></textarea>
                <br />
                <div className="emailPhone">
                  <input
                    onChange={this.handleEmail}
                    className="email"
                    type="email"
                    id="email"
                    value={this.state.email}
                    placeholder="Twój e-mail"
                    title="podaj swój e-mail"
                    required
                  />

                  <input
                    onChange={this.handlePhone}
                    className="phone"
                    type="text"
                    id="phone"
                    value={this.state.phone}
                    placeholder="Twój numer tel."
                    title="podaj swój nr telefonu"
                    required
                  />
                </div>
                <div className="submitButtonWrapper">
                  <button className="submitButton">Wyślij</button>
                </div>
              </form>
              <Prompt
                when={!this.state.isEmpty}
                message="Nie przesłałeś formularza, czy na pewno chcesz przejść dalej?"
              />
            </div>
            <div className="mainContact">
              <div className="dataContact">
                <div className="logoContact">
                  <img src={logo} alt="logo" />
                </div>
                <span>tel: 726 98 63 63</span> <br />
                <span>tel: 697 63 48 93</span>
                <br />
                <br />
                <span>e-mail: biuro@ecogreen-oze.pl</span> <br /> <br />
                <span>ul. Konopnickiej 20b</span>
                <br />
                <span>88-300 Mogilno</span> <br />
                <br />
                <span>NIP: 5571675171, REGON: 341517360</span>
                <br />
                <span>nr konta: 23 1020 1505 0000 0002 0224 1594</span>
                <br />
              </div>
              <div className="mapContact">
                <h3>Znajdź nas na mapie</h3>
                <a
                  href="https://www.google.com/maps/place/ECO+GREEN/@52.6655386,17.9412218,17z/data=!3m1!4b1!4m5!3m4!1s0x4704a114a73be6b5:0xd66a4373443351b4!8m2!3d52.6655386!4d17.9434105"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="mapa" src={mapa} alt="fotowoltaika-mapa" />
                </a>
              </div>
            </div>
          </>
        )
    }
}

export default Contact;
